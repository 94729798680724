<template>
  <!-- :width="dialogWidth" -->
  <div class="elv-reports-compute-popup">
    <el-dialog
      v-model="dialogVisibleVal"
      :before-close="onCloseCompute"
      class="dialog_ha"
      :close-on-click-modal="false"
    >
      <!-- 头部 -->
      <!-- 主要内容 --科目组弹窗-->
      <div
        v-if="eventItem.showType === 'NORMAL' || eventItem.showType === 'CHILDREN'"
        class="elv-reports-compute-popup-main"
      >
        <!-- 左侧 一级弹窗 -->
        <div v-loading="loadingAccount" class="elv-reports-compute-popup-main__con">
          <div>
            <div class="elv-reports-compute-popup-main__con-title">
              <span class="elv-reports-compute-popup-main__con-title-main overfllow_ell">
                {{ t('common.account') }} -
                {{
                  eventItem.showType === 'NORMAL'
                    ? formatLanguageContent(eventItem.name)
                    : formatLanguageContent(eventItem.parentName)
                }}
                <span v-if="eventItem.showType === 'CHILDREN'"> - {{ eventItem.symbol }}</span>
              </span>
              <span class="elv-reports-compute-popup-main__con-title-img" @click="onCloseCompute()"
                ><img src="@/assets/img/close.png" alt=""
              /></span>
            </div>
            <div class="elv-reports-compute-popup-main__con-USD">
              {{
                route.params?.profileId === '120' ? 'USD' : t('common.amountsUnit', { unit: t(`common.${props.unit}`) })
              }}
            </div>
            <div v-if="dateTime.title" class="elv-reports-compute-popup-main__con-date">
              <span class="elv-reports-compute-popup-main__con-date-left">{{ dateTime?.title }}</span>
              <span class="elv-reports-compute-popup-main__con-date-right">{{
                formatDate(dateTime?.fromDate) + ` ${t('common.to')} ` + formatDate(dateTime?.toDate)
              }}</span>
            </div>
            <div v-if="dateTime.date" class="elv-reports-compute-popup-main__con-date">
              <!-- <span class="elv-reports-compute-popup-main__con-date-left">{{ dateTime.date }}</span> -->
              <span class="elv-reports-compute-popup-main__con-date-right">{{ formatDate(dateTime?.date) }}</span>
            </div>
          </div>
          <div class="elv-reports-compute-popup-main__con-table">
            <ul class="elv-reports-compute-popup-main__con-table__title-ul">
              <li style="width: 34px"></li>
              <li style="width: 140px; text-align: start">{{ t('report.category') }}</li>
              <li style="min-width: 90px">{{ t('common.txCount') }}</li>
              <li style="min-width: 89px">{{ t('common.amount') }}</li>
              <li style="width: 33px"></li>
            </ul>
            <ul class="elv-reports-compute-popup-main__con-table__con-ul li_weight">
              <li style="width: 34px"></li>
              <li style="width: 140px; text-align: left">{{ t('common.openingBalance') }}</li>
              <li style="min-width: 90px">{{ accountData.data?.openingBalance?.tx }}</li>
              <li
                style="min-width: 89px"
                :title="`${`${
                  entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `
                }`}${accountData.data?.openingBalance?.amount}`"
              >
                {{
                  route.params?.profileId === '120'
                    ? numDrCrHandle(
                        props.unit,
                        accountData.data?.openingBalance?.amount,
                        eventItem.defaultBalanceType,
                        '-',
                        false,
                        false
                      )
                    : numDrCrHandle(props.unit, accountData.data?.openingBalance?.amount, eventItem.defaultBalanceType)
                }}
              </li>
              <li style="width: 33px"></li>
            </ul>
            <ul
              v-for="(item, index) in accountData.data?.list"
              :key="index"
              :class="{ li_event: eventIndex === index }"
              class="elv-reports-compute-popup-main__con-table__con-ul li_event_cursor"
              @click="onOpenEvent(item, index)"
            >
              <li class="li-svg" style="width: 34px">
                <SvgIcon
                  v-if="item?.extra?.operator === 'PLUS' || item?.operator === 'PLUS'"
                  name="add-reports"
                  width="18"
                  height="18"
                  :fill="eventIndex === index ? '#ffffff' : '#636B75'"
                ></SvgIcon>
                <SvgIcon
                  v-if="item.operator === 'MINUS' || item?.extra?.operator === 'MINUS'"
                  name="subtract-reports"
                  width="18"
                  height="18"
                  :fill="eventIndex === index ? '#ffffff' : '#636B75'"
                ></SvgIcon>
              </li>
              <li style="width: 140px; text-align: left" class="overfllow_ell">
                <el-tooltip
                  :show-arrow="false"
                  effect="light"
                  placement="top"
                  popper-class="elv-table-tips"
                  overlay-classname="elv-table-tips"
                  :show-after="500"
                  :disabled="item?.event ? false : true"
                >
                  {{ formatLanguageContent(item.categoryNo ? currentEventData(item.categoryNo) : item.event) }}
                  <template #content>
                    <div class="elv-compute-tips-content">
                      {{ formatLanguageContent(item.categoryNo ? currentEventData(item.categoryNo) : item.event) }}
                    </div>
                  </template>
                </el-tooltip>
              </li>
              <li
                style="min-width: 90px"
                :title="`${`${
                  entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `
                }`}${item?.tx}`"
              >
                {{ item?.tx }}
              </li>
              <li
                style="min-width: 89px"
                :title="`${`${
                  entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `
                }`}${item?.amount}`"
              >
                {{
                  route.params?.profileId === '120'
                    ? numDrCrHandle(props.unit, item?.amount, eventItem.defaultBalanceType, '-', false, false)
                    : numDrCrHandle(props.unit, item?.amount, eventItem.defaultBalanceType)
                }}
              </li>
              <li style="width: 33px; padding-top: 4px">
                <SvgIcon
                  name="jiantou-reports"
                  width="18"
                  height="18"
                  :fill="eventIndex === index ? '#ffffff' : '#636B75'"
                ></SvgIcon>
              </li>
            </ul>

            <ul class="elv-reports-compute-popup-main__con-table__con-ul li_weight">
              <li class="li-svg" style="width: 34px">
                <SvgIcon name="equal-reports" width="18" height="18" fill="#636B75"></SvgIcon>
              </li>
              <li style="width: 140px; text-align: left">{{ t('common.closingBalance') }}</li>
              <li style="min-width: 90px" class="li_font_Barlow">{{ accountData.data?.closingBalance?.tx }}</li>
              <li
                style="min-width: 89px"
                class="li_font_Barlow"
                :title="`${`${
                  entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `
                }`}${accountData.data?.closingBalance?.amount}`"
              >
                {{
                  route.params?.profileId === '120'
                    ? numDrCrHandle(
                        props.unit,
                        accountData.data?.closingBalance?.amount,
                        eventItem.defaultBalanceType,
                        '-',
                        false,
                        false
                      )
                    : numDrCrHandle(props.unit, accountData.data?.closingBalance?.amount, eventItem.defaultBalanceType)
                }}
              </li>

              <li style="width: 33px"></li>
            </ul>
          </div>
        </div>

        <!-- 右侧 二级弹窗 -->
        <div v-if="isShowEventDialog" v-loading="loadingEvent" class="elv-reports-compute-popup-main__con compute_two">
          <div>
            <div class="elv-reports-compute-popup-main__con-title">
              <span class="elv-reports-compute-popup-main__con-title-main overfllow_ell"
                >{{ t('common.journalEntries') }} -
                {{ formatLanguageContent(currentEventData(event.categoryNo)) }}
                <span v-if="eventItem.symbol"> - {{ eventItem.symbol }}</span>
              </span>
              <span class="elv-reports-compute-popup-main__con-title-img" @click="onCloseEventDialog()"
                ><img src="@/assets/img/close.png" alt=""
              /></span>
            </div>
            <div class="elv-reports-compute-popup-main__con-USD">
              {{
                route.params?.profileId === '120' ? 'USD' : t('common.amountsUnit', { unit: t(`common.${props.unit}`) })
              }}
            </div>
            <div v-if="dateTime.title" class="elv-reports-compute-popup-main__con-date">
              <span class="elv-reports-compute-popup-main__con-date-left">{{ dateTime?.title }}</span>
              <span class="elv-reports-compute-popup-main__con-date-right">{{
                formatDate(dateTime?.fromDate) + ` ${t('common.to')} ` + formatDate(dateTime?.toDate)
              }}</span>
            </div>
            <div v-if="dateTime.date" class="elv-reports-compute-popup-main__con-date">
              <!-- <span class="elv-reports-compute-popup-main__con-date-left">{{ dateTime.date }}</span> -->
              <span class="elv-reports-compute-popup-main__con-date-right">{{ formatDate(dateTime?.date) }}</span>
            </div>
            <div class="elv-reports-compute-popup-main__con-abi">{{ eventData.data?.abi }}</div>
            <div class="elv-reports-compute-popup-main__con-des">
              {{ formatLanguageContent(eventData.data?.description) }}
            </div>
            <div class="elv-reports-compute-popup-main__con-table">
              <ul class="elv-reports-compute-popup-main__con-table__title-ul">
                <li style="width: 34px"></li>
                <li style="width: 155px; text-align: start">{{ t('common.account') }}</li>
                <li style="width: 70px">{{ t('common.debit') }}</li>
                <li style="width: 70px">{{ t('common.credit') }}</li>
              </ul>

              <ul
                v-for="(item, index) in eventData.data?.list"
                :key="index"
                :class="{ li_event: item?.accountId === eventItem.fieldId || item?.accountId === eventItem.parentId }"
                class="elv-reports-compute-popup-main__con-table__con-ul"
              >
                <li class="li-svg" style="width: 34px">
                  {{ item?.credit !== null ? 'Cr' : 'Dr' }}
                </li>
                <li style="width: 155px; text-align: left" class="overfllow_ell">
                  <el-tooltip
                    :show-arrow="false"
                    effect="light"
                    placement="top"
                    popper-class="elv-table-tips"
                    overlay-classname="elv-table-tips"
                    :show-after="500"
                    :disabled="item?.accountName ? false : true"
                  >
                    {{ formatLanguageContent(currentAccountData(item?.accountId)) }}
                    <template #content>
                      <div class="elv-compute-tips-content">
                        {{ formatLanguageContent(currentAccountData(item?.accountId)) }}
                      </div>
                    </template>
                  </el-tooltip>
                </li>
                <li
                  style="width: 70px"
                  class="li_font_Barlow"
                  :title="`${`${
                    entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `
                  }`}${item?.debit}`"
                >
                  {{
                    route.params?.profileId === '120'
                      ? numDrCrHandle(props.unit, item?.debit, eventItem.defaultBalanceType, '-', false, false)
                      : numDrCrHandle(props.unit, item?.debit)
                  }}
                </li>
                <li
                  style="width: 70px"
                  class="li_font_Barlow"
                  :title="`${`${
                    entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `
                  }`}${item?.credit}`"
                >
                  {{
                    route.params?.profileId === '120'
                      ? numDrCrHandle(props.unit, item?.credit, eventItem.defaultBalanceType, '-', false, false)
                      : numDrCrHandle(props.unit, item?.credit)
                  }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <!-- 主要内容 --公式弹窗-->
      <div v-if="eventItem.showType === 'AGG'" v-loading="loadingAgg" class="elv-reports-compute-popup-main">
        <div class="elv-reports-compute-popup-main__con">
          <div class="elv-reports-compute-popup-main__con-title">
            <span class="elv-reports-compute-popup-main__con-title-main overfllow_ell">{{
              formatLanguageContent(eventItem.name)
            }}</span>
            <span class="elv-reports-compute-popup-main__con-title-img">
              <img src="@/assets/img/close.png" alt="" @click="onCloseCompute()" />
            </span>
          </div>
          <div class="elv-reports-compute-popup-main__con-table">
            <ul
              v-for="(item, index) in formulaData?.list"
              :key="index"
              :class="{
                li_line_top: index === 0
              }"
              class="elv-reports-compute-popup-main__con-table__con-ul"
            >
              <li class="li-svg" style="width: 34px">
                <SvgIcon
                  :name="calculationFormulaDirection(item)"
                  width="18"
                  height="18"
                  :fill="eventIndex === index ? '#ffffff' : '#636B75'"
                />
              </li>
              <li style="width: 100%; min-width: 280px; text-align: left" class="overfllow_ell">
                <el-tooltip
                  :show-arrow="false"
                  effect="light"
                  placement="top"
                  popper-class="elv-reports-compute-popup-tips"
                  overlay-classname="elv-reports-compute-popup-tips"
                  :show-after="500"
                  :disabled="item?.name?.length >= 40 ? false : true"
                >
                  {{ formatLanguageContent(item?.name) }}
                  <template #content>
                    <div class="elv-reports-compute-popup-tips_content">
                      {{ formatLanguageContent(item?.name) }}
                    </div>
                  </template>
                </el-tooltip>
              </li>
            </ul>
            <ul class="elv-reports-compute-popup-main__con-table__con-ul li_weight">
              <li class="li-svg" style="width: 34px">
                <SvgIcon name="equal-reports" width="18" height="18" fill="#636B75" />
              </li>
              <li style="width: 100%; min-width: 280px; text-align: left" class="overfllow_ell">
                <el-tooltip
                  :show-arrow="false"
                  effect="light"
                  placement="top"
                  popper-class="elv-reports-compute-popup-tips"
                  overlay-classname="elv-reports-compute-popup-tips"
                  :show-after="500"
                  :disabled="eventItem?.name?.length >= 40 ? false : true"
                >
                  {{ formatLanguageContent(eventItem.name) }}
                  <template #content>
                    <div class="elv-reports-compute-popup-tips_content">
                      {{ formatLanguageContent(eventItem.name) }}
                    </div>
                  </template>
                </el-tooltip>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import { find } from 'lodash-es'
import { useI18n } from 'vue-i18n'
import { ElMessage } from 'element-plus'
import ReportsApi from '@/api/ReportsApi'
import { useEntityStore } from '@/stores/modules/entity'
import { useReportStore } from '@/stores/modules/reports/index'
import { numDrCrHandle, formatLanguageContent } from '@/lib/utils'
import { reportEventList, exportAccountList } from '@/config/reports'

const { t, locale } = useI18n()
const reportStore = useReportStore()
const entityStore = useEntityStore()
const { entityDetail } = storeToRefs(entityStore)

const route = useRoute()
const emit = defineEmits(['closeComputePopup'])
const props = defineProps({
  // 是否展示计算弹窗
  dialogVisible: {
    type: Boolean,
    required: true
  },
  dateTime: {
    type: Object,
    required: true
  },
  eventItem: {
    type: Object,
    required: true
  },
  unit: {
    type: String,
    required: true
  }
})

// 接口参数
const name = ref('')
const event = ref() // 弹窗组中二级弹窗的event参数
const dialogWidth = ref('384px')
const isShowEventDialog = ref(false)
const eventIndex = ref()

const topVal = computed(() => {
  return `${121 + eventIndex.value * 24}px`
})

const entityId = computed(() => {
  return String(route.params?.entityId as string)
})

const dialogVisibleVal = ref(true)
const loadingAccount = ref(false)
const loadingEvent = ref(false)
const loadingAgg = ref(false)

const accountData = reactive<any>({
  data: {
    openingBalance: {},
    closingBalance: {},
    list: []
  }
})
const eventData = reactive<any>({
  data: {
    abi: '',
    description: '',
    list: []
  }
})
const formulaData: any = reactive({
  list: []
})

const currentEventData = computed(() => (categoryNo: string) => {
  return find(reportEventList, { categoryNo })?.categoryName ?? ''
})

const currentAccountData = computed(() => (accountId: string) => {
  return find(exportAccountList, { accountId })?.accountName ?? ''
})

const calculationFormulaDirection = computed(() => {
  return (formulaItem: any) => {
    if (formulaItem?.code === 'cashflow-300-5') {
      // 金额为正时符号位“+”，金额为负时符号为“-”
      const formulaValue = find(formulaItem.fieldValues, (item: any) => {
        return item.fromDate === props.dateTime.fromDate && item.toDate === props.dateTime.toDate
      })?.value
      return formulaValue >= 0 || formulaValue === '' || formulaValue === undefined ? 'add-reports' : 'subtract-reports'
    }
    return formulaItem?.extra?.operator === 'PLUS' ? 'add-reports' : 'subtract-reports'
  }
})

// 关闭整体弹窗
const onCloseCompute = () => {
  emit('closeComputePopup')
}

// 关闭事件弹窗
const onCloseEventDialog = () => {
  eventIndex.value = -1
  isShowEventDialog.value = false
}

const formatDate = (date: string) => {
  if (locale.value === 'zh') return date
  return dayjs(date).format('DD MMM YYYY')
}

// 获取科目弹窗数据
const getAccountData = async () => {
  try {
    loadingAccount.value = true
    const params: any = {
      accountId: String(props.eventItem.fieldId)
    }
    params.date = name.value === 'reports-balance-sheet' ? props.dateTime.date : (params.date = props.dateTime.toDate)
    const { data } = await ReportsApi.getComputeAccountApi(entityId.value, params)
    accountData.data = data
    loadingAccount.value = false
  } catch (error: any) {
    loadingAccount.value = false
    ElMessage.error(formatLanguageContent(error.message))
  }
}

// 获取事件弹窗数据
const getEventData = async () => {
  try {
    loadingEvent.value = true
    const params: any = {
      accountId: String(props.eventItem.fieldId),
      categoryNo: event.value.categoryNo
    }
    params.date = name.value === 'reports-balance-sheet' ? props.dateTime.date : props.dateTime.toDate
    const { data } = await ReportsApi.getComputeEventApi(entityId.value, params)
    eventData.data = data
  } catch (error: any) {
    ElMessage.error(formatLanguageContent(error.message))
  } finally {
    loadingEvent.value = false
  }
}

// 获取公式弹窗数
const getFormulaData = async () => {
  try {
    loadingAgg.value = true
    // 注意查找公式
    const formulaList = props.eventItem.aggRule.map((item: any) => {
      return find(reportStore.financialFieldConfig, { fieldId: String(item) })
    })
    formulaData.list = formulaList
  } catch (error: any) {
    ElMessage.error(formatLanguageContent(error.message))
  } finally {
    loadingAgg.value = false
  }
}

// 打开事件弹窗
const onOpenEvent = (eventItem: any, index: number) => {
  if (eventIndex.value !== index) {
    eventIndex.value = index
    isShowEventDialog.value = true
    event.value = eventItem
    getEventData()
  }
}

watchEffect(() => {
  dialogVisibleVal.value = props.dialogVisible
})
watchEffect(() => {
  name.value = String(route.name)
})
watchEffect(() => {
  if (props.eventItem.showType === 'AGG') {
    dialogWidth.value = '312px'
    getFormulaData()
  } else {
    getAccountData()
  }
})
</script>

<style lang="scss" scoped>
:deep(.elv-compute-tips-content) {
  padding: 16px;
  font-family: 'Plus Jakarta Sans';
  font-weight: 500;
  font-size: 11px;
  color: #0e1420;
}

:deep(.elv-reports-compute-popup-tips_content) {
  padding: 16px;
  font-family: 'Plus Jakarta Sans';
  font-weight: 500;
  font-size: 11px;
  color: #0e1420;
}

.elv-reports-compute-popup {
  .elv-reports-compute-popup-main {
    display: flex;

    .elv-reports-compute-popup-main__con {
      // min-width: v-bind('dialogWidth');
      color: $elv-color-FFFFFF;
      background: $elv-color-FFFFFF;
      box-shadow:
        0px 0px 1px rgba(0, 0, 0, 0.25),
        0px 2px 30px rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      overflow: hidden;

      .elv-reports-compute-popup-main__con-title {
        display: flex;
        justify-content: space-between;
        padding: 0 8px;
        margin-top: 8px;
        // height: 20px;

        .elv-reports-compute-popup-main__con-title-main {
          font-family: 'Plus Jakarta Sans';
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
          color: $elv-color-000000;
        }

        .elv-reports-compute-popup-main__con-title-img {
          img {
            width: 20px;
            height: 20px;
            cursor: pointer;
          }
        }
      }

      .elv-reports-compute-popup-main__con-USD {
        font-family: 'Plus Jakarta Sans';
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: $elv-theme-tertiary-text-color;
        padding: 0 8px;
        margin-top: 4px;
        zoom: 0.92;
        -moz-transform: scale(0.92);
        -moz-transform-origin: top left;
      }

      .elv-reports-compute-popup-main__con-date {
        display: flex;
        padding: 0 8px;
        margin-top: 2px;

        .elv-reports-compute-popup-main__con-date-left {
          font-family: 'Plus Jakarta Sans';
          font-weight: 700;
          font-size: 12px;
          zoom: 0.92;
          -moz-transform: scale(0.92);
          -moz-transform-origin: top left;
          line-height: 14px;
          color: $elv-theme-quaternary-text-color;
          height: 14px;
          padding-right: 4px;
        }

        .elv-reports-compute-popup-main__con-date-right {
          font-family: 'Plus Jakarta Sans';
          font-weight: 400;
          font-size: 12px;
          zoom: 0.92;
          -moz-transform: scale(0.92);
          -moz-transform-origin: top left;
          line-height: 14px;
          color: $elv-theme-quaternary-text-color;
          height: 14px;
        }
      }

      .elv-reports-compute-popup-main__con-abi {
        font-family: 'Plus Jakarta Sans';
        font-weight: 400;
        font-size: 12px;
        zoom: 0.92;
        -moz-transform: scale(0.92);
        -moz-transform-origin: top left;
        line-height: 14px;
        color: $elv-theme-quaternary-text-color;
        padding: 0 8px;
        margin-top: 4px;
        word-break: break-word;
      }

      .elv-reports-compute-popup-main__con-des {
        font-family: 'Plus Jakarta Sans';
        font-weight: 400;
        font-size: 12px;
        zoom: 0.92;
        -moz-transform: scale(0.92);
        -moz-transform-origin: top left;
        line-height: 14px;
        color: $elv-theme-quaternary-text-color;
        padding: 0 8px;
        margin-top: 2px;
        word-break: break-word;
      }

      .elv-reports-compute-popup-main__con-table {
        margin-top: 8px;
        width: 100%;

        .elv-reports-compute-popup-main__con-table__title-ul {
          display: flex;
          height: 24px;
          background: $elv-color-EEF4FB;
          border-top: 1px solid $elv-color-E4E7EB;
          border-bottom: 1px solid $elv-color-E4E7EB;
          padding: 0;
          margin: 0;

          li {
            font-family: 'Plus Jakarta Sans';
            font-weight: 600;
            font-size: 12px;
            zoom: 0.92;
            -moz-transform: scale(0.92);
            -moz-transform-origin: top left;
            line-height: 24px;
            height: 24px;
            color: $elv-color-666D74;
            border-right: 1px solid $elv-color-E4E7EB;
            padding: 0 9px;
            box-sizing: border-box;
            text-align: end;
          }

          .li_font_Barlow {
            font-family: 'Barlow';
            font-weight: 400;
          }

          &:last-child {
            border-right: none;
          }
        }

        .elv-reports-compute-popup-main__con-table__con-ul {
          display: flex;
          border-bottom: 1px solid $elv-color-E4E7EB;
          padding: 0;
          margin: 0;

          li {
            min-height: 20px;
            font-family: 'Plus Jakarta Sans';
            font-weight: 500;
            font-size: 12px;
            // line-height: 20px;
            zoom: 0.92;
            -moz-transform: scale(0.92);
            -moz-transform-origin: top left;
            color: $elv-theme-base-text-color;
            text-align: right;
            border-right: 1px solid $elv-color-E4E7EB;
            padding: 0 9px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
          }

          &:last-child {
            border-right: none;
          }
        }

        .li_line_top {
          li {
            border-top: 1px solid $elv-color-E4E7EB;
          }
        }

        .li_event_cursor {
          li {
            cursor: pointer;
            font-family: 'Plus Jakarta Sans';
            font-weight: 500;
          }

          &:hover {
            background: $elv-color-EEF4FB;
          }
        }

        .li_event {
          background: $elv-color-1343BF !important;

          li {
            color: $elv-color-FFFFFF;
          }
        }

        .li_weight {
          li {
            font-family: 'Plus Jakarta Sans';
            font-weight: 700;
          }
        }
      }
    }
  }

  .li-svg {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .overfllow_ell {
    // overflow: hidden;
    // white-space: nowrap;
    // text-overflow: ellipsis;
    word-wrap: break-word;
  }
}

:deep(.dialog_ha) {
  width: 30vh;
  min-width: v-bind('dialogWidth');
}

.compute_two {
  position: absolute;
  top: v-bind('topVal');
  left: 355px;
  width: 303px !important;
}
</style>
<style lang="scss">
.elv-reports-compute-popup {
  .el-dialog {
    box-shadow: none;
  }

  .el-dialog__header {
    display: none;
  }

  .el-dialog__body {
    padding: 0;
    background: rgba(0, 0, 0, 0.5);
    border-radius: none;
  }
}
</style>
